import React, { useContext, useState } from "react"
import { useNavigate, Link } from "react-router-dom"

import { Paper, InputBase, Button, IconButton } from "@mui/material"

// icons
import SearchIcon from "@mui/icons-material/Search"
import LogoutIcon from "@mui/icons-material/Logout"
import AddCommentIcon from "@mui/icons-material/AddComment" // eslint-disable-line
import LinkIcon from "@mui/icons-material/Link" // eslint-disable-line

import { AuthContext } from "../../context/auth-context"

import "./header-layout-component.css"
import { config } from "../../config"

export const HeaderLayout = ({ children }) => {
  const { isLoggedIn, logout } = useContext(AuthContext) // eslint-disable-line
  const navigate = useNavigate()
  const [keywords, setKeywords] = useState("")

  const searchHandler = () => {
    navigate(`/search/${keywords}`)
    window.location.reload()
  }

  const onKeyPress = e => {
    if (e.code === "Enter" && !!e.target.value.length) {
      navigate(`/search/${e.target.value}`)
    }
  }

  const onChangeSearch = e => {
    if (!e.target.value.length) {
      navigate(`/`, {
        replace: true,
      })
    }
    setKeywords(e.target.value)
  }

  const logoutHandler = () => {
    logout()
    navigate("/")
    window.location.reload()
  }

  return (
    <div>
      <div className="nav">
        <Link className="nav-link" to="/">
          JobBot Console
        </Link>

        <div className="nav-right">
          {isLoggedIn && (
            <>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 410,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by Firstname, Query, Email or Location"
                  inputProps={{ "aria-label": "search keywords" }}
                  value={keywords}
                  onChange={onChangeSearch}
                  onKeyDown={onKeyPress}
                />
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={searchHandler}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>

              <Button
                sx={{
                  m: "0 24px",
                }}
                variant="contained"
                startIcon={<AddCommentIcon />}
                onClick={() => navigate("/reengagement")}
              >
                Re-engagement
              </Button>
              <Button
                sx={{
                  m: "0 24px",
                }}
                variant="contained"
                startIcon={<LinkIcon />}
                onClick={() => {
                  window.open(config.mobotConsoleUrl, "_blank")
                }}
              >
                Mobot-console
              </Button>

              <Button
                sx={{
                  m: "0 24px",
                }}
                variant="contained"
                color="error"
                endIcon={<LogoutIcon />}
                onClick={logoutHandler}
              >
                Logout
              </Button>
            </>
          )}
        </div>
      </div>

      {children}
    </div>
  )
}
