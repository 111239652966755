import React, { useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { CustomTable } from "../../components/common/CustomTable"
import { Spinner } from "../../components/common/loader"

import "./search-page.css"

const TABLE_HEADERS = [
  "Name",
  "Platform",
  "Location",
  "Email",
  "Query",
  "Created",
  "Updated",
]

export const SearchPage = () => {
  const { pathname } = useLocation()
  let { keywords } = useParams()
  const [users, setUsers] = useState(null)

  // eslint-disable-next-line
  const { data: searchedData, isLoading } = useAxiosQuery(
    [ApiKeys.Search],
    consoleApi.searchUsers.bind(null, {
      keywords: keywords,
      offset: 0,
      sortBy: "logged",
      order: "desc",
    }),
    {
      onSuccess: ({ data }) => {
        setUsers(data)
      },
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    },
  )

  if (isLoading) {
    return (
      <div className="search">
        <h2>{`Search results (Loading...)`}</h2>
        <hr />
        <Spinner />
      </div>
    )
  }

  if (!users?.totalRecords) {
    return (
      <div className="search">
        <h2>{`Search results not found for "${keywords}"`}</h2>
        <hr />
      </div>
    )
  }

  return (
    <div className="search">
      <h2>{`Search results found (${users.totalRecords}) for "${keywords}"`}</h2>
      <hr />
      <CustomTable
        data={users.data}
        headers={TABLE_HEADERS}
        totalCount={users.totalRecords}
        viewProfile={null}
        pathname={pathname}
      />
    </div>
  )
}
