import React, { useContext } from "react" // eslint-disable-line
import { useLocation } from "react-router-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { AuthContext } from "../../context/auth-context"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { CustomTable } from "../../components/common/CustomTable"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./subscriptions-page.css"

const INFO = {
  subscriptions:
    "List of users who have opted for alerts for a particular query.",
}

const TABLE_HEADERS = [
  "Name",
  "Platform",
  "Location",
  "Entries",
  "Clicks",
  "Query",
  "Created",
  "Updated",
]

export const SubscriptionsPage = () => {
  const { data } = useContext(AuthContext)
  const { pathname } = useLocation()

  const { data: subscriptions, isLoading } = useAxiosQuery(
    ApiKeys.Subscriptions,
    consoleApi.getSubscriptions.bind(null, {
      offset: 0,
      sortBy: "logged",
      order: "desc",
    }),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading) {
    return (
      <div className="subscriptions">
        <div className="subscriptionsHeader">
          <h2>{`Subscriptions (${data?.data.subscriptions})`}</h2>
          <TooltipInfo title={INFO?.subscriptions} />
        </div>
        <hr />
        <Spinner />
      </div>
    )
  }

  return (
    <div className="subscriptions">
      <div className="subscriptionsHeader">
        <h2>{`Subscriptions (${data?.data.subscriptions})`}</h2>
        <TooltipInfo title={INFO?.subscriptions} />
      </div>
      <hr />
      <CustomTable
        data={subscriptions?.data}
        totalCount={data?.data.subscriptions}
        viewProfile={null}
        headers={TABLE_HEADERS}
        pathname={pathname}
      />
    </div>
  )
}
