import env from "react-dotenv"

const version = env.APP_VERSION || "unknown"
const packageName = env.APP_PACKAGE_NAME
const jobbotApiUrl = env.JOBBOT_API_URL
const jobbotConsoleApiUrl = env.JOBBOT_CONSOLE_API_URL
const mobotConsoleUrl = env.MOBOT_CONSOLE_URL

export const config = {
  mobotConsoleUrl,
  package: {
    name: packageName,
    version,
  },
  api: {
    jobbot: jobbotApiUrl,
    console: jobbotConsoleApiUrl,
  },
  google: {
    api: {
      places: {
        key: "", // "AIzaSyAIEe9zmSw_5aOcurcqJ1UO51Wn010DyYE"
      },
    },
  },
  toast: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
  },
}
