import axios from "axios"
import axiosRetry from "axios-retry"

import { config } from "../../config"
import {
  camelizeInterceptor,
  decamelizeInterceptor, // eslint-disable-line
  pageToOffsetInterceptor,
  reformatArrayInterceptor,
  saveOriginalInterceptor,
  errorInterceptor,
} from "../axios-interceptors"

export const ApiKeys = {
  ReEngagement: "re-engagement",
  Campaign: "campaign",
}

class JobbotApi {
  constructor() {
    this.http = axios.create({
      baseURL: config.api.jobbot,
      timeout: 30000,
      headers: {},
    })

    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(camelizeInterceptor)
    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)

    // this.http.interceptors.request.use(decamelizeInterceptor)
    this.http.interceptors.request.use(pageToOffsetInterceptor)
    this.http.interceptors.request.use(reformatArrayInterceptor)
  }

  setDefaultHeader = ({ header, value }) => {
    this.http.defaults.headers.common[header] = value
  }

  removeDefaultHeader = header => {
    delete this.http.defaults.headers.common[header]
  }

  addCampaign = data => this.http.post("/scheduleMessage", data)

  getCampaigns = ({ offset }) =>
    this.http.get(`/campaignDetails?offset=${offset}`)
}

export const jobbotApi = new JobbotApi()
