import React, { useContext } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  Outlet,
  Link,
} from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { ToastContainer, Slide } from "react-toastify"

import { config } from "./config"
import { BaseLayout } from "./components/layouts/base-layout-component"
import { AuthProvider, AuthContext } from "./context/auth-context"
import { LoginPage } from "./pages/login/login-page"
import { SearchPage } from "./pages/search/search-page"
import { ProfilesPage } from "./pages/profiles/profiles-page"
import { SubscriptionsPage } from "./pages/subscriptions/subscriptions-page"
import { UsersPage } from "./pages/users/users-page"
import { ClicksPage } from "./pages/clicks/clicks-page"
import { ReengagementPage } from "./pages/reengagement/reengagement"
import { CampaignPage } from "./pages/campaign/campaign-page"

import "react-toastify/dist/ReactToastify.css"

const queryClient = new QueryClient()

function PrivateOutlet() {
  const { token } = useContext(AuthContext)
  return token ? <Outlet /> : <Navigate to="/login" />
}

export const App = () => (
  <AuthProvider>
    <Router>
      <QueryClientProvider client={queryClient}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <BaseLayout>
            <ToastContainer {...config.toast} transition={Slide} />
            <Link to="/login" relative="/" />

            <Routes>
              <Route path="/login" element={<LoginPage />} />

              <Route path="/" element={<PrivateOutlet />}>
                <Route path="search">
                  <Route path=":keywords" element={<SearchPage />} />
                </Route>

                <Route path="profiles" element={<ProfilesPage />} />
                <Route path="subscriptions" element={<SubscriptionsPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="clicks" element={<ClicksPage />} />
                <Route path="reengagement" element={<ReengagementPage />} />
                <Route path="campaign" element={<CampaignPage />} />
              </Route>
            </Routes>
          </BaseLayout>
        </QueryParamProvider>
      </QueryClientProvider>
    </Router>
  </AuthProvider>
)
