import React from "react" // eslint-disable-line
import { useLocation } from "react-router-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { jobbotApi, ApiKeys } from "../../lib/api/jobbot-api"
import { CustomTable } from "../../components/common/CustomTable"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./campaign-page.css"

const INFO = {
  campaign: "Total number of Campaigns created for re-engaging the users.",
}

const TABLE_HEADERS = [
  "Name",
  "Creation Date",
  "Interaction Date",
  "Location",
  "Job Role",
  "Frequency",
  "Clicks",
  "Limit",
  "Milestone 1",
  "Milestone 2",
  "Milestone 3",
  "Milestone 4",
]

const TABLE_TOOLTIPS = [
  "Interaction Date",
  "Frequency",
  "Limit",
  "Milestone 1",
  "Milestone 2",
  "Milestone 3",
  "Milestone 4",
]

export const CampaignPage = () => {
  const { pathname } = useLocation()

  const { data: campaign, isLoading } = useAxiosQuery(
    [ApiKeys.Campaign],
    jobbotApi.getCampaigns.bind(null, { offset: 0 }),
  )

  if (isLoading) {
    return (
      <div className="campaign">
        <div className="campaignHeader">
          <h2>{`Campaigns (Loading...)`}</h2>
          <TooltipInfo title={INFO?.campaign} />
        </div>
        <hr />
        <Spinner />
      </div>
    )
  }

  return (
    <div className="campaign">
      <div className="campaignHeader">
        <h2>{`Campaigns (${campaign.totalCampaigns ?? 0}) `}</h2>
        <TooltipInfo title={INFO?.campaign} />
      </div>
      <hr />
      <CustomTable
        data={campaign.data}
        totalCount={campaign.totalCampaigns}
        viewProfile={null}
        headers={TABLE_HEADERS}
        tooltips={TABLE_TOOLTIPS}
        pathname={pathname}
      />
    </div>
  )
}
