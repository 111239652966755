import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { orderBy, sortBy } from "lodash"
import { format } from "date-fns"
import {
  Box,
  TextField,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Autocomplete,
} from "@mui/material"

// MUI-X packages
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"

// icons
import RefreshIcon from "@mui/icons-material/Refresh"
import AddCommentIcon from "@mui/icons-material/AddComment" // eslint-disable-line

import { AuthContext } from "../../context/auth-context"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./clicks-page.css"

const INFO = {
  clicks: "Reports of clicks by date and location, where Location is Optional.",
}

const getFormatttedData = (range, location) => {
  let startTime = ""
  let endTime = ""
  if (range[0] && range[1]) {
    startTime = format(new Date(range[0]["$d"]), "yyyy-MM-dd")
    endTime = format(new Date(range[1]["$d"]), "yyyy-MM-dd")
  }
  return { startTime, endTime, location }
}

const getDaysArray = function (s, e) {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d))
  }
  return a
}

const getFormattedChartData = (data, range) => {
  let startDate = ""
  if (range[0]) {
    startDate = format(new Date(range[0]["$d"]), "yyyy-MM-dd")
  }

  const fakeDates = getDaysArray(
    new Date(startDate),
    new Date(data.at(-1)?.date),
  ).map(d => format(d, "yyyy-MM-dd"))

  // const fakeDates = getDaysArray(
  //   new Date(data[0]?.date),
  //   new Date(data.at(-1)?.date),
  // ).map(d => format(d, "yyyy-MM-dd"))

  const fakeData = fakeDates.map(d => ({
    date: d,
    clicks: 0,
  }))

  const formattedData = data.map(({ date, clicks }) => ({
    date: format(new Date(date), "yyyy-MM-dd"),
    clicks: clicks,
  }))

  const sorted = sortBy([...new Set([...fakeData, ...formattedData])], ["date"])
  const ordered = orderBy(sorted, ["date", "clicks"], ["asc", "desc"])

  const formattedChartData = ordered.map(({ date, clicks }) => ({
    Date: format(new Date(date), "dd-MMM-yy"),
    Clicks: clicks,
  }))

  let setObj = new Set() // create key value pair from array of array
  return formattedChartData.reduce((acc, item) => {
    if (!setObj.has(item.Date)) {
      setObj.add(item.Date, item)
      acc.push(item)
    }
    return acc
  }, []) //converting back to array from mapobject
}

export const ClicksPage = () => {
  const { data } = useContext(AuthContext)
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState([null, null])
  const [chartData, setChartData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [country, setCountry] = useState("")

  const { data: locations, isLoading: isLoadingLocations } = useAxiosQuery(
    ApiKeys.ClickLocations,
    consoleApi.getClickLocations,
  )

  const { mutate: getClickReports, isLoading: isLoadingClicks } =
    useAxiosMutation(consoleApi.getClicks, {
      onSuccess: ({ data }) => {
        setChartData(getFormattedChartData(data, dateRange))
      },
      onError: err => {
        console.error(err)
        setHasError(true)
      },
    })

  if (isLoadingClicks || isLoadingLocations) {
    return (
      <div className="clicks">
        <div className="clicksHeader">
          <h2>{`Clicks (Loading...)`}</h2>
          <TooltipInfo title={INFO?.clicks} />
        </div>
        <hr />

        <div className="filters">
          <div>
            <FormControl sx={{ m: 2, minWidth: 200 }}>
              <FormLabel>Select Country</FormLabel>
              <Autocomplete
                disablePortal
                id="country"
                options={locations?.data}
                renderInput={params => (
                  <TextField {...params} label="Country" />
                )}
                onChange={(e, value) => {
                  setCountry(value)
                  getClickReports(getFormatttedData(dateRange, value))
                }}
              />
            </FormControl>
          </div>

          <div className="datePicker">
            <FormLabel>Select a Specific Date Range</FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "Start Date", end: "End Date" }}
            >
              <DateRangePicker
                disableMaskedInput
                value={dateRange}
                inputFormat="YYYY/MM/DD"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField {...endProps} />
                  </>
                )}
                onChange={newValue => {
                  setDateRange(newValue)
                }}
                onAccept={value => {
                  getClickReports(getFormatttedData(value, country))
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <Spinner />
      </div>
    )
  }

  if (hasError) {
    return (
      <div className="clicks">
        <div className="clicksHeader">
          <h2>{`Clicks (${data?.data.clicks})`}</h2>
          <TooltipInfo title={INFO?.clicks} />
        </div>
        <hr />

        <div className="filters">
          <div>
            <FormControl sx={{ m: 2, minWidth: 200 }}>
              <FormLabel>Select Country</FormLabel>
              <Autocomplete
                disablePortal
                id="country"
                options={locations?.data}
                renderInput={params => (
                  <TextField {...params} label="Country" />
                )}
                onChange={(e, value) => {
                  setCountry(value)
                  getClickReports(getFormatttedData(dateRange, value))
                }}
              />
            </FormControl>
          </div>

          <div className="datePicker">
            <FormLabel>Select a Specific Date Range</FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "Start Date", end: "End Date" }}
            >
              <DateRangePicker
                disableMaskedInput
                value={dateRange}
                inputFormat="YYYY/MM/DD"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField {...endProps} />
                  </>
                )}
                onChange={newValue => {
                  setDateRange(newValue)
                }}
                onAccept={value => {
                  getClickReports(getFormatttedData(value, country))
                }}
                onError={(reason, value) =>
                  toast.error(`Please enter a valid date range. ${reason}`)
                }
              />
            </LocalizationProvider>
          </div>
        </div>

        <p className="error">
          Something went wrong while fetching Click Reports.
        </p>
      </div>
    )
  }

  if (!chartData.length) {
    return (
      <div className="clicks">
        <div className="clicksHeader">
          <h2>{`Clicks (${data?.data.clicks})`}</h2>
          <TooltipInfo title={INFO?.clicks} />
        </div>
        <hr />

        <div className="filters">
          <div>
            <FormControl sx={{ m: 2, minWidth: 200 }}>
              <FormLabel>Select Country</FormLabel>
              <Autocomplete
                disablePortal
                id="country"
                options={locations?.data}
                renderInput={params => (
                  <TextField {...params} label="Country" />
                )}
                onChange={(e, value) => {
                  setCountry(value)
                  getClickReports(getFormatttedData(dateRange, value))
                }}
              />
            </FormControl>
          </div>

          <div className="datePicker">
            <FormLabel>Select a Specific Date Range</FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "Start Date", end: "End Date" }}
            >
              <DateRangePicker
                disableMaskedInput
                value={dateRange}
                inputFormat="YYYY/MM/DD"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField {...endProps} />
                  </>
                )}
                onChange={newValue => {
                  setDateRange(newValue)
                }}
                onAccept={value => {
                  getClickReports(getFormatttedData(value, country))
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <p className="notFound">
          No data found. Select a valid Date Range and Country to view the
          reports.
        </p>
      </div>
    )
  }

  return (
    <div className="clicks">
      <div className="clicksHeader">
        <h2>{`Clicks (${data?.data.clicks})`}</h2>
        <TooltipInfo title={INFO?.clicks} />
      </div>
      <hr />

      <div className="filters">
        <div>
          <FormControl sx={{ m: 2, minWidth: 200 }}>
            <FormLabel>Select Country</FormLabel>
            <Autocomplete
              disablePortal
              id="country"
              options={locations?.data}
              renderInput={params => <TextField {...params} label="Country" />}
              onChange={(e, value) => {
                setCountry(value)
                getClickReports(getFormatttedData(dateRange, value))
              }}
            />
          </FormControl>
        </div>

        <div className="datePicker">
          <FormLabel>Select a Specific Date Range</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Start Date", end: "End Date" }}
          >
            <DateRangePicker
              disableMaskedInput
              value={dateRange}
              inputFormat="YYYY/MM/DD"
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField {...endProps} />
                </>
              )}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              onAccept={value => {
                getClickReports(getFormatttedData(value, country))
              }}
              onError={(reason, value) =>
                toast.error(`Please enter a valid date range. ${reason}`)
              }
            />
          </LocalizationProvider>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={400}
          height={400}
          data={chartData}
          margin={{ top: 16, right: 0, bottom: 16, left: 0 }}
        >
          <Line type="monotone" dataKey="Clicks" stroke="#8884d8" />
          <XAxis dataKey="Date" />
          <YAxis type="number" allowDecimals={false} />
          <Tooltip />
          <Legend verticalAlign="bottom" height={36} />
        </LineChart>
      </ResponsiveContainer>

      <div className="footerBtn">
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() =>
              getClickReports(getFormatttedData(dateRange, country))
            }
            variant="contained"
            startIcon={<RefreshIcon />}
            disabled={isLoadingClicks || isLoadingLocations}
          >
            Refresh Click Reports
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() =>
              navigate("/reengagement", {
                replace: true,
              })
            }
            variant="contained"
            startIcon={<AddCommentIcon />}
          >
            Add New Campaign
          </Button>
        </Stack>
      </div>
    </div>
  )
}
