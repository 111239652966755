import React, { useContext } from "react"
import { useLocation } from "react-router-dom"

import { AuthContext } from "../../context/auth-context"
import { HeaderLayout } from "./header-layout-component"
import { DashboardPage } from "../../pages/dashboard/dashboard-page"

import "./base-layout-component.css"

export const BaseLayout = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext)
  const { pathname } = useLocation()

  const path = pathname?.split("/")[1] || "" // e.g. /profile
  const isActiveDashboard =
    isLoggedIn &&
    path !== "search" &&
    path !== "reengagement" &&
    path !== "campaign"

  return (
    <div>
      <HeaderLayout />

      <div className="pageWrap">
        {isActiveDashboard && <DashboardPage />}
        {children}
      </div>
    </div>
  )
}
