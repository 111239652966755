import React, { useContext } from "react"
import { useLocation } from "react-router-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { AuthContext } from "../../context/auth-context"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { CustomTable } from "../../components/common/CustomTable"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./users-page.css"

const INFO = {
  users:
    "List of users with name, query, search-query (query for which user will receive alerts), location, platform, clicks, email.",
}

const TABLE_HEADERS = [
  "Name",
  "Location User",
  "Location Subs",
  "Search",
  "Query",
  "Clicks",
  "Created",
  "Updated",
]

export const UsersPage = () => {
  const { data } = useContext(AuthContext)
  const { pathname } = useLocation()

  const { data: users, isLoading } = useAxiosQuery(
    ApiKeys.Users,
    consoleApi.getUsers.bind(null, {
      offset: 0,
      sortBy: "logged",
      order: "desc",
    }),
  )

  if (isLoading) {
    return (
      <div className="users">
        <div className="usersHeader">
          <h2>{`Users (${data?.data.users})`}</h2>
          <TooltipInfo title={INFO?.users} />
        </div>
        <hr />
        <Spinner />
      </div>
    )
  }

  return (
    <div className="users">
      <div className="usersHeader">
        <h2>{`Users (${data?.data.users})`}</h2>
        <TooltipInfo title={INFO?.users} />
      </div>
      <hr />
      <CustomTable
        data={users?.data}
        totalCount={data?.data.users}
        viewProfile={null}
        headers={TABLE_HEADERS}
        pathname={pathname}
      />
    </div>
  )
}
