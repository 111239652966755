import { useContext, useRef } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { AuthContext } from "../../context/auth-context"
import { consoleApi } from "../../lib/api/console-api"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { Spinner } from "../../components/common/loader"

import "./login-page.css"

export const LoginPage = () => {
  const usernameInputRef = useRef()
  const passwordInputRef = useRef()
  const { login, token, isLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  const mutation = useAxiosMutation(consoleApi.login, {
    onSuccess: ({ data: { token, expiresIn } }) => {
      login(token)
      navigate("/")
    },
    onError: err => {
      console.error("Login failed", err)
      // return toast.error(err.response.data.message)
      return toast.error("Incorrect Login Credentials.")
    },
  })

  const submitHandler = e => {
    e.preventDefault()
    const data = {
      username: usernameInputRef.current.value,
      password: passwordInputRef.current.value,
    }
    mutation.mutate(data)
  }

  if (token) {
    return <Navigate to="/" />
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <section className="auth">
      <h1>Sign In to JobBot Console</h1>
      <form onSubmit={submitHandler}>
        <div className="control">
          <label htmlFor="username">Username</label>
          <input type="text" id="username" required ref={usernameInputRef} />
        </div>
        <div className="control">
          <label htmlFor="password">Password</label>
          <input
            className="password"
            type="password"
            id="password"
            required
            ref={passwordInputRef}
          />
        </div>
        <div className="login-actions">
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading" : "Login"}
          </button>
        </div>
      </form>
    </section>
  )
}
