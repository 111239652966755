import React from "react"
import ReactDOM from "react-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { ApiKeys, consoleApi } from "../../lib/api/console-api"
import { downloadPDF } from "../../lib/helpers/index"
import { Spinner } from "./loader"
import Card from "./Card"

import noImg from "../../assets/img/user-default.jpg"
import "./UserInfo.css"

const Backdrop = ({ onBackdropClick }) => {
  return <div className="backdrop" onClick={onBackdropClick} />
}

const ModalOverlay = ({ isLoading, profileData, CVData }) => {
  if (isLoading) {
    return (
      <Card className="user-info">
        <Spinner />
      </Card>
    )
  }

  return (
    <Card className="user-info">
      <header className="modal-header">
        <h5>{`${profileData.firstName}  "${
          profileData.lastQuery || `All`
        }"`}</h5>
      </header>
      <div className="user-profile">
        <hr />
        <img
          className="user-profile-pic"
          src={profileData.profilePic || noImg}
          alt="Profile Pic"
        />

        <div className="user-name-location">
          <h3>{`${profileData.firstName}  ${profileData.lastName || ""}`}</h3>
          <p>{`${profileData.loc.city},  ${profileData.loc.country}`}</p>
        </div>
        <h5 className="user-last-query">{profileData.lastQuery}</h5>

        <div className="user-search-queries">
          <h6>Search Queries:</h6>
          {profileData.keywords?.map((keyword, idx) => (
            <p key={idx} className="search-query">
              {keyword}
            </p>
          )) || ""}
        </div>

        <hr />
      </div>
      <footer className="actions">
        <div>
          <strong>Email:</strong>
          <p>{profileData.email || "None"}</p>
        </div>

        {profileData?.fileType && (
          <div>
            <strong>Docs:</strong>
            <p>
              <a
                href={CVData.profile}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  profileData?.fileType
                    ? downloadPDF(
                        CVData.profile,
                        CVData.fileType,
                        profileData.docs[0],
                      )
                    : alert("File Not found")
                }
                download
              >
                {profileData?.docs[0] ? profileData?.docs[0] : "None"}
              </a>
            </p>
          </div>
        )}

        <div>
          <strong>Links:</strong>
          <p>
            {profileData.links?.map(d => (
              <div>
                <a href={d} target="_blank" rel="noreferrer">
                  {d}
                </a>
              </div>
            )) || ""}
          </p>
        </div>
      </footer>
    </Card>
  )
}

const UserInfo = ({ closeProfile, uid }) => {
  const { data: profile, isLoading: isLoadingProfile } = useAxiosQuery(
    [ApiKeys.Dashboard, { id: uid }],
    consoleApi.getProfile,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const { data: CV, isLoading: isLoadingCV } = useAxiosQuery(
    [ApiKeys.CV, { id: uid }],
    consoleApi.getCV,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const isLoading = isLoadingProfile || isLoadingCV
  const profileData = profile?.data || {}
  const CVData = CV?.data || {}

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onBackdropClick={closeProfile} />,
        document.getElementById("backdrop-root"),
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          isLoading={isLoading}
          profileData={profileData}
          CVData={CVData}
        />,
        document.getElementById("overlay-root"),
      )}
    </>
  )
}

export default UserInfo
