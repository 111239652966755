import React, { useContext, useState } from "react"
import { useNavigate, Link } from "react-router-dom" // eslint-disable-line
import { toast } from "react-toastify"
import { format } from "date-fns" // eslint-disable-line
import urlRegex from "url-regex"

import {
  Box,
  Modal,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  Button,
  Stack,
  Alert,
} from "@mui/material"

// MUI-X packages
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"

// icons
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import AddAlertIcon from "@mui/icons-material/AddAlert"

import { AuthContext } from "../../context/auth-context"
import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { consoleApi, ApiKeys } from "../../lib/api/console-api" // eslint-disable-line
import { jobbotApi } from "../../lib/api/jobbot-api"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

// styles
import "./reengagement.css"

const CLIENT_ERROR_CODES = [202, 203, 204]

const INFO = {
  reengagement:
    "Re-Engage the users again by selected filters, add a new Campaign",
  frequency:
    "Job Alert Frequency selected by user(none, asap, daily, weekly and all comprises of all 3)",
  limit: "Number of users re-engaged for the campaign",
}

const getFormatttedDate = range => {
  let startDate = ""
  let endDate = ""
  if (range[0] && range[1]) {
    startDate = format(new Date(range[0]["$d"]), "yyyy-MM-dd")
    endDate = format(new Date(range[1]["$d"]), "yyyy-MM-dd")
  }
  return { startDate, endDate }
}

export const ReengagementPage = () => {
  const { data } = useContext(AuthContext) // eslint-disable-line
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [creationDateRange, setCreationDateRange] = useState([null, null])
  const [interactionDateRange, setInteractionDateRange] = useState([null, null])
  const [country, setCountry] = useState("")
  const [jobTitle, setJobTitle] = useState("")
  const [limit, setLimit] = useState(1) // default limit
  const [frequency, setFrequency] = useState("none")
  const [selectBy, setSelectBy] = useState("newest")
  const [customMsg, setCustomMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [open, setOpen] = useState(false)

  const { data: locations, isLoading: isLoadingLocations } = useAxiosQuery(
    ApiKeys.UserLocations,
    consoleApi.getUserLocations,
  )

  const { mutate: addNewEngagement, isLoading: isEngaging } = useAxiosMutation(
    jobbotApi.addCampaign,
    {
      onSuccess: data => {
        // console.log(data)
        if (CLIENT_ERROR_CODES.includes(data.status)) {
          return toast.error("Unable to add campaign due to no user found.")
        } else {
          return toast.success("New campaign added sucessfully!")
        }
      },
      onError: err => {
        console.error(err)
        return toast.error("Unable to add campaign due to no user found.")
      },
    },
  )

  const onSubmit = () => {
    const creationDate = getFormatttedDate(creationDateRange)
    const interactionDate = getFormatttedDate(interactionDateRange)
    if (!name || !country) {
      return toast.error(
        "Please enter required fields. (e.g. Campaign Name, Country)",
      )
    } else {
      let data = {
        name: name,
        location: country,
        limit: limit,
        latestFirst: selectBy === "newest" ? true : false,
      }
      if (creationDate?.startDate) {
        data.created_startTime = creationDate?.startDate
        data.created_endTime = creationDate?.endDate
      }
      if (interactionDate?.startDate) {
        data.last_startTime = interactionDate?.startDate
        data.last_endTime = interactionDate?.endDate
      }
      if (jobTitle) {
        data.jobRole = jobTitle
      }
      if (frequency !== "none") {
        data.frequency = frequency
      }
      if (customMsg) {
        data.customMessage = customMsg
      }
      if (errorMsg) {
        data.errorMessage = errorMsg
      }
      // console.log(data)

      try {
        addNewEngagement(data)
        setOpen(false)
      } catch {
        return toast.error("Something went wrong while creating the campaign.")
      }
    }
  }

  if (isEngaging || isLoadingLocations) {
    return (
      <div className="container reengagement">
        <div className="reengagementHeader">
          <h2>{`Re-Engagement (Loading...)`}</h2>
          <TooltipInfo title={INFO?.reengagement} />
        </div>
        <hr />
        <Spinner />
      </div>
    )
  }

  return (
    <div className="container reengagement">
      <div className="reengagementHeader">
        <h2>{`Re-Engagement`}</h2>
        <TooltipInfo title={INFO?.reengagement} />
      </div>

      <div className="campaignButtonWrap">
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() =>
              navigate("/campaign", {
                replace: true,
              })
            }
            variant="contained"
          >
            View Campaign List
          </Button>
        </Stack>
      </div>
      <hr />

      <Box
        component="form"
        sx={{ display: "flex", flexWrap: "wrap" }}
        noValidate
        autoComplete="off"
      >
        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="campaign-name" required>
            Campaign Name
          </FormLabel>
          <TextField
            required
            id="name"
            onChange={e => {
              if (e.target.value.length > 100) {
                return toast.error(
                  "Please enter a name not more  than 100 characters.",
                )
              } else {
                setName(e.target.value)
              }
            }}
          />
          <FormHelperText id="campaign-name-helper-text">
            Maximum Length is 100 characters
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel htmlFor="creation-date">Creation Date</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              start: "Start Date",
              end: "End Date",
            }}
          >
            <DateRangePicker
              inputFormat="YYYY/MM/DD"
              value={creationDateRange}
              onChange={newValue => {
                setCreationDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel htmlFor="interaction-date">
            Last Interaction Date
          </FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              start: "Start Date",
              end: "End Date",
            }}
          >
            <DateRangePicker
              inputFormat="YYYY/MM/DD"
              value={interactionDateRange}
              onChange={newValue => {
                setInteractionDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="country" required>
            Select or Type Country
          </FormLabel>
          <Autocomplete
            disablePortal
            id="country"
            options={locations?.data}
            renderInput={params => <TextField {...params} label="Country" />}
            onChange={(e, value) => setCountry(value)}
          />
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="job-title">Job Title</FormLabel>
          <TextField
            required
            id="jobTitle"
            onChange={e => {
              if (e.target.value?.match(/^[\w\-\s]+$/)) {
                setJobTitle(e.target.value)
              } else {
                return toast.error("Please enter a valid job role.")
              }
            }}
          />
          <FormHelperText id="job-title-helper-text">
            Type alphabets only, do not use special symbols
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="limit">
            Limit <TooltipInfo title={INFO?.limit} fontSize="small" />
          </FormLabel>
          <TextField
            id="limit"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              if (e.target.value > 500) {
                return toast.error("Please enter a limit not more than 500.")
              } else {
                setLimit(e.target.value)
              }
            }}
            required
            defaultValue={1}
          />
          <FormHelperText id="limit-helper-text">
            Maximum Limit is 500
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel
            id="alert-frequencies"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Alert Frequencies
            <TooltipInfo title={INFO?.frequency} fontSize="small" />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="none"
            onChange={e => setFrequency(e.target.value)}
          >
            <FormControlLabel
              value="none"
              control={<Radio size="small" />}
              label="None"
            />
            <FormControlLabel
              value="daily"
              control={<Radio size="small" />}
              label="Daily"
            />
            <FormControlLabel
              value="weekly"
              control={<Radio size="small" />}
              label="Weekly"
            />
            <FormControlLabel
              value="asap"
              control={<Radio size="small" />}
              label="ASAP"
            />
            <FormControlLabel
              value="all"
              control={<Radio size="small" />}
              label="All"
            />
          </RadioGroup>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="select-by">Select By</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="newest"
            onChange={e => setSelectBy(e.target.value)}
          >
            <FormControlLabel
              value="newest"
              control={<Radio size="small" />}
              label="Newest"
            />
            <FormControlLabel
              value="oldest"
              control={<Radio size="small" />}
              label="Oldest"
            />
          </RadioGroup>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="custom-msg">Custom Message</FormLabel>
          <TextField
            id="custom-msg"
            placeholder="Hey {{{first_name}}}! 👋 I have got great news. My inventory has been upgraded to over 9 Million jobs. Are you looking for new career opportunities? 🔍\nWrite me a “show” to start the search."
            multiline
            minRows={3}
            onChange={e => {
              if (urlRegex().test(e.target.value)) {
                return toast.error(
                  "Invalid message. You can't enter any hyperlinks.",
                )
              } else {
                setCustomMsg(e.target.value)
              }
            }}
          />
          <FormHelperText id="custom-msg-helper-text">
            <strong>
              Note: You can not enter any hyperlinks in the message-box due to
              facebook policy. <br />
              Note: Please do not forget to add "Write me a “show” to start the
              search.".
            </strong>
          </FormHelperText>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: 500 }}>
          <FormLabel id="error-msg">Error Message</FormLabel>
          <TextField
            id="error-msg"
            multiline
            minRows={3}
            onChange={e => setErrorMsg(e.target.value)}
          />
        </FormControl>
      </Box>

      <Box component="div">
        <Button
          sx={{
            m: "24px 12px",
            p: "auto 32px",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
            toast.warning(
              "Please make sure if you want add the campaign. Once campaign is created you cannot revert the operation.",
            )
          }}
          disabled={isEngaging || !name || !country}
        >
          ADD New Campaign
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        // keepMounted
        // disablePortal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "250px",
            width: "50%",
            bgcolor: "background.paper",
            border: "1px solid grey",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="alertWrapper">
            <h5 className="alertText">Alert</h5>
            <AddAlertIcon color="error" />
          </div>

          <div>
            <p>
              Are you sure, you want to Re-engage <strong>{limit}</strong>{" "}
              users?
            </p>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="warning">
                Warning: You cannot revert the operation after clicking "Yes".
              </Alert>
            </Stack>
          </div>

          <Button
            className="alertBtn"
            variant="contained"
            color="success"
            startIcon={<CheckIcon />}
            onClick={() => onSubmit()}
          >
            Yes
          </Button>
          <Button
            className="alertBtn"
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={() => {
              setOpen(false)
            }}
          >
            No
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
