import axios, { AxiosInstance } from "axios" //eslint-disable-line no-unused-vars
import axiosRetry from "axios-retry"

import { config } from "../../config"
import {
  camelizeInterceptor,
  decamelizeInterceptor, // eslint-disable-line
  pageToOffsetInterceptor,
  reformatArrayInterceptor,
  saveOriginalInterceptor,
  errorInterceptor,
} from "../axios-interceptors"

export const ApiKeys = {
  Dashboard: "dashboard",
  Profiles: "profiles",
  Subscriptions: "subscriptions",
  Users: "users",
  Clicks: "clicks",
  Profile: "profile",
  ClickLocations: "click-locations",
  Search: "search",
  CV: "cv",
  UserLocations: "user-locations",
}

class ConsoleApi {
  constructor() {
    this.http = axios.create({
      baseURL: config.api.console,
      timeout: 3600000,
      headers: {},
    })

    axiosRetry(this.http, { retries: 3 })

    this.http.interceptors.response.use(camelizeInterceptor)
    this.http.interceptors.response.use(response => response, errorInterceptor)
    this.http.interceptors.response.use(saveOriginalInterceptor)

    // this.http.interceptors.request.use(decamelizeInterceptor)
    this.http.interceptors.request.use(pageToOffsetInterceptor)
    this.http.interceptors.request.use(reformatArrayInterceptor)
  }

  setDefaultHeader = ({ header, value }) => {
    this.http.defaults.headers.common[header] = value
  }

  removeDefaultHeader = header => {
    delete this.http.defaults.headers.common[header]
  }

  login = data => this.http.post("/login", data)

  logout = () => this.http.get("/logout")

  getAllData = () => this.http.get("/allData")

  getProfiles = ({ offset, sortBy, order }) =>
    this.http.get(`/profiles?offset=${offset}&sortBy=${sortBy}&order=${order}`)

  getProfile = ({ id }) => this.http.get(`/profile/${id}`)

  getSubscriptions = ({ offset, sortBy, order }) =>
    this.http.get(`/subs?offset=${offset}&sortBy=${sortBy}&order=${order}`)

  getClicks = ({ startTime, endTime, location }) =>
    this.http.post("/clicksdata", { startTime, endTime, location })

  getClickLocations = () => this.http.get("/click-location")

  searchUsers = ({ keywords, offset, sortBy, order }) =>
    this.http.post(`/search?offset=${offset}&sortBy=${sortBy}&order=${order}`, {
      q: keywords,
    })

  getCV = ({ id }) => this.http.get(`doc-by-id?id=${id}`)

  getUserLocations = () => this.http.get("/users-location")

  getUsers = ({ offset, sortBy, order }) =>
    this.http.get(`/users?offset=${offset}&sortBy=${sortBy}&order=${order}`)
}

export const consoleApi = new ConsoleApi()
