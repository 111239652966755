import React, { useCallback, useEffect, useState, createContext } from "react"
import { toast } from "react-toastify"

import { Spinner } from "../components/common/loader"
import { consoleApi } from "../lib/api/console-api"
import { jobbotApi } from "../lib/api/jobbot-api"

// server error codes
const ERROR_CODES = [401, 402, 403, 404, 500, 501, 502, 504]

export const AuthContext = createContext({
  logout: () => null,
  login: () => null,
  refreshUser: () => null,
  user: null,
  data: null,
  token: null,
  isLoggedIn: false,
  isLoading: false,
})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"))
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState(null)
  const [data, setData] = useState(null)

  const logout = useCallback(() => {
    try {
      const { data } = consoleApi.logout() // eslint-disable-line
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      if (ERROR_CODES.includes(error.response?.status)) {
        console.log(error)
        toast.error("Something went wrong. Please try again.")
      }
    }

    setToken(null)
    setIsLoggedIn(false)
    localStorage.removeItem("token")
  }, [])

  const login = useCallback(token => {
    setToken(token)
    localStorage.setItem("token", token)
  }, [])

  const refreshUser = async () => {
    const { data: user } = await consoleApi.getUser()
    setUser(user)
  }

  useEffect(() => {
    if (token) {
      consoleApi.setDefaultHeader({
        header: "Authorization",
        value: `JWT ${token}`,
      })

      jobbotApi.setDefaultHeader({
        header: "Authorization",
        value: `JWT ${token}`,
      })

      const fetchAllData = async () => {
        try {
          const { data } = await consoleApi.getAllData()
          setData(data)
          setIsLoading(false)
          setIsLoggedIn(true)
        } catch (error) {
          console.log(error)
          if (ERROR_CODES.includes(error.response?.status)) {
            logout(token)
          }
        }
      }

      fetchAllData()
    }
  }, [token, logout])

  return (
    <AuthContext.Provider
      value={{ data, user, token, isLoggedIn, logout, login, refreshUser }}
    >
      {isLoading && token ? <Spinner /> : children}
    </AuthContext.Provider>
  )
}
