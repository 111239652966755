import React from "react"
import { useNavigate } from "react-router-dom"
import cn from "classnames"

import {
  AVAILABLE_DETAILED_STATISTICS,
  UNAVAILABLE_DETAILED_STATISTICS,
} from "../../constants"
import { useAxiosQuery } from "../../hooks/use-axios-query"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { toCapitalCase } from "../../utils/string"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./dashboard-page.css"

const INFO = {
  dashboard: "Overall statistics of users.",
  profiles: "Total number of users who have completed their profiles.",
  subscriptions:
    "Total number of users who have saved their search and will receive jobs.",
  users: "Total number of users interacted with the Bot.",
  clicks: "Total number of clicks, users who have clicked on job-cards.",
  messages: "Total number of messages sent and received by/from the Bot.",
}

export const DashboardPage = () => {
  const navigate = useNavigate()

  const { data, isLoading } = useAxiosQuery(
    ApiKeys.Dashboard,
    consoleApi.getAllData,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading) {
    return (
      <div className="dashboard">
        <div className="dashboardHeader">
          <h2>Dashboard</h2>
          <TooltipInfo title={INFO?.dashboard} />
        </div>
        <Spinner />
      </div>
    )
  }

  return (
    <div className="dashboard">
      <div className="dashboardHeader">
        <h2>Dashboard</h2>
        <TooltipInfo title={INFO?.dashboard} />
      </div>

      <hr />
      <div className="statistics">
        {Object.entries(data?.data).map(([name, value], i) => (
          <div className="statistic" key={i}>
            <p className="statistic-count">{value}</p>
            <div className="statistic-name">
              <p
                className={cn({
                  "statistic-link":
                    AVAILABLE_DETAILED_STATISTICS.includes(name),
                })}
                disabled={UNAVAILABLE_DETAILED_STATISTICS.includes(name)}
                onClick={
                  AVAILABLE_DETAILED_STATISTICS.includes(name)
                    ? () => navigate("/" + name)
                    : null
                }
              >
                {toCapitalCase(name)}
              </p>
              <TooltipInfo title={INFO[`${name}`]} fontSize="small" />
            </div>
          </div>
        ))}
      </div>
      <br />
      <hr />
    </div>
  )
}
