import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { format } from "date-fns"
import cn from "classnames"

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"

import { useAxiosMutation } from "../../hooks/use-axios-mutation"
import { downloadPDF } from "../../lib/helpers/index"
import { consoleApi, ApiKeys } from "../../lib/api/console-api" // eslint-disable-line no-unused-vars
import { jobbotApi } from "../../lib/api/jobbot-api"
import { Spinner } from "./loader"
import { TooltipInfo } from "./ToolTip/ToolTip"

import "./CustomTable.css"

const SORT_KEY = [
  "name",
  "location",
  "email",
  "created",
  "logged",
  "location-user",
  "location-subs",
]

const INFO = {
  "interaction date":
    "Range of dates in which user has last interacted with bot. (Last Activity date with bot)",
  frequency:
    "Job Alert Frequency selected by user(none, asap, daily, weekly and all comprises of all 3)",
  limit: "Number of users re-engaged for the campaign",
  "milestone 1":
    "Number of users who are successfully re-engaged and have not any action after re-engagement",
  "milestone 2":
    "Number of users who have sent some other message(other than 'show') after re-engagement",
  "milestone 3":
    "Number of users who have sent 'show' to bot after re-engagement",
  "milestone 4":
    "Number of users who have clicked on job cards which appeared after they wrote  'show' at the time of re-engagement",
}

export const CustomTable = ({
  data = [],
  viewProfile,
  headers,
  tooltips = [],
  totalCount = 0,
  pathname,
}) => {
  let { keywords } = useParams()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [tableData, setTableData] = useState(data)
  const [offset, setOffset] = useState(0)
  const [sortKey, setSortKey] = useState("logged") // default
  const [sortOrder, setSortOrder] = useState("desc") // default
  const path = pathname?.split("/")[1] || "" // extract main route

  const { mutate: fetchProfiles, isLoading: isLoadingProfiles } =
    useAxiosMutation(consoleApi.getProfiles, {
      // eslint-disable-next-line
      onSuccess: ({ data: data }) => {
        setTableData(prevData => {
          return [...prevData, ...data.data]
        })
        setOffset(prevOffset => prevOffset + 1)
      },
      onError: err => {
        console.error("Profile list fetching failed", err)
        return toast.error("Something went wrong.")
      },
    })
  const { mutate: sortProfiles, isLoading: isSortingProfiles } =
    useAxiosMutation(consoleApi.getProfiles, {
      // eslint-disable-next-line no-useless-rename
      onSuccess: ({ data: data }) => {
        setTableData([...data.data])
      },
      onError: err => {
        console.error("Profile list sorting failed", err)
        return toast.error("Something went wrong.")
      },
    })

  const { mutate: fetchSubscriptions, isLoading: isLoadingSubscriptions } =
    useAxiosMutation(consoleApi.getSubscriptions, {
      // eslint-disable-next-line
      onSuccess: ({ data: data }) => {
        setTableData(prevData => {
          return [...prevData, ...data.data]
        })
        setOffset(prevOffset => prevOffset + 1)
      },
      onError: err => {
        console.error("Subscriptions list fetching failed", err)
        return toast.error("Something went wrong.")
      },
    })
  const { mutate: sortSubscriptions, isLoading: isSortingSubscriptions } =
    useAxiosMutation(consoleApi.getSubscriptions, {
      // eslint-disable-next-line no-useless-rename
      onSuccess: ({ data: data }) => {
        setTableData([...data.data])
      },
      onError: err => {
        console.error("Subscriptions list sorting failed", err)
        return toast.error("Something went wrong.")
      },
    })

  const { mutate: fetchUsers, isLoading: isLoadingUsers } = useAxiosMutation(
    consoleApi.getUsers,
    {
      // eslint-disable-next-line
      onSuccess: ({ data: data }) => {
        setTableData(prevData => {
          return [...prevData, ...data.data]
        })
        setOffset(prevOffset => prevOffset + 1)
      },
      onError: err => {
        console.error("Users list fetching failed", err)
        return toast.error("Something went wrong.")
      },
    },
  )
  const { mutate: sortUsers, isLoading: isSortingUsers } = useAxiosMutation(
    consoleApi.getUsers,
    {
      // eslint-disable-next-line no-useless-rename
      onSuccess: ({ data: data }) => {
        setTableData([...data.data])
      },
      onError: err => {
        console.error("Users list sorting failed", err)
        return toast.error("Something went wrong.")
      },
    },
  )

  const { mutate: fetchCampaigns, isLoading: isLoadingCampaigns } =
    useAxiosMutation(jobbotApi.getCampaigns, {
      // eslint-disable-next-line
      onSuccess: ({ data: data }) => {
        setTableData(prevData => {
          return [...prevData, ...data.data]
        })
        setOffset(prevOffset => prevOffset + 1)
      },
      onError: err => {
        console.error("Campaign list fetching failed", err)
        return toast.error("Something went wrong.")
      },
    })

  const { mutate: search, isLoading: isLoadingSearch } = useAxiosMutation(
    consoleApi.searchUsers,
    {
      // eslint-disable-next-line
      onSuccess: ({ data: data }) => {
        console.log("search: ", data)
        setTableData(prevData => {
          return [...prevData, ...data.data]
        })
        setOffset(prevOffset => prevOffset + 1)
      },
      onError: err => {
        console.error("Search failed", err)
        return toast.error("Something went wrong.")
      },
    },
  )
  const { mutate: orderedSearch, isLoading: isOrderingSearch } =
    useAxiosMutation(consoleApi.searchUsers, {
      // eslint-disable-next-line no-useless-rename
      onSuccess: ({ data: data }) => {
        console.log("search: ", data)
        setTableData([...data.data])
      },
      onError: err => {
        console.error("Search failed", err)
        return toast.error("Something went wrong.")
      },
    })

  const isSorting =
    isSortingProfiles ||
    isSortingSubscriptions ||
    isSortingUsers ||
    isOrderingSearch

  const isLoading =
    isLoadingProfiles ||
    isLoadingSubscriptions ||
    isLoadingSearch ||
    isLoadingCampaigns ||
    isLoadingUsers ||
    isSorting

  const handleChangePage = (event, newPage, pathname) => {
    setPage(newPage)

    if ((page + 1) * rowsPerPage === tableData.length) {
      switch (path) {
        case "profiles":
          fetchProfiles({
            offset: offset + 1,
            sortBy: sortKey,
            order: sortOrder,
          })
          break

        case "subscriptions":
          fetchSubscriptions({
            offset: offset + 1,
            sortBy: sortKey,
            order: sortOrder,
          })
          break

        case "users":
          fetchUsers({ offset: offset + 1, sortBy: sortKey, order: sortOrder })
          break

        case "campaign":
          fetchCampaigns({ offset: offset + 1 })
          break

        case "search":
          search({
            keywords: keywords,
            offset: offset + 1,
            sortBy: sortKey,
            order: sortOrder,
          })
          break

        default:
      }
    }
  }

  const handleCellOrder = (e, cellHeader, cellOrder) => {
    e.preventDefault()
    if (SORT_KEY.includes(cellHeader)) {
      const isNewHeader = sortKey !== cellHeader

      setSortKey(cellHeader)
      setSortOrder(cellOrder)
      if (isNewHeader) {
        setPage(0)
      }

      switch (path) {
        case "profiles":
          sortProfiles({
            offset: isNewHeader ? 0 : offset,
            sortBy: cellHeader,
            order: cellOrder,
          })
          break

        case "subscriptions":
          sortSubscriptions({
            offset: isNewHeader ? 0 : offset,
            sortBy: cellHeader,
            order: cellOrder,
          })
          break

        case "users":
          sortUsers({
            offset: isNewHeader ? 0 : offset,
            sortBy: cellHeader,
            order: cellOrder,
          })
          break

        // case "campaign":
        //   fetchCampaigns({ offset: isNewHeader ? 0 : offset, sortBy: cellHeader, order: cellOrder })
        //   break

        case "search":
          orderedSearch({
            keywords: keywords,
            offset: isNewHeader ? 0 : offset,
            sortBy: cellHeader,
            order: cellOrder,
          })
          break

        default:
      }
    } else {
      return toast.warning("Oops! Ordering of this cell is unavailable yet.")
    }
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const onClickDownload = (e, uid, fileName) => {
    e.preventDefault()
    consoleApi
      .getCV({ id: uid })
      .then(res => {
        downloadPDF(
          res.data.data?.profile,
          res.data.data?.fileType,
          `${fileName}`,
        )
      })
      .catch(err => console.log(err))
  }

  const getProfile = ({ uid, file, fileType, fileName, isBase64 }) => {
    const formattedFileName = `${
      fileName ?? Math.random().toString(36).slice(2)
    }.${fileType}`

    if (isBase64) {
      return (
        <button
          className="btnCV"
          type="button"
          onClick={e => onClickDownload(e, uid, fileName)}
        >
          {formattedFileName}
        </button>
      )
    }

    if (file) {
      return (
        <a href={`https://${file}`} target="_blank" rel="noreferrer">
          {file?.slice(0, 100) + "..."}
        </a>
      )
    }
  }

  const getPlatformData = client => {
    let platform = "NULL"
    switch (client) {
      case 1:
        platform = "FB"
        break
      case 2:
        platform = "TG"
        break

      default:
    }
    return platform
  }

  const getSortKey = cellHeader => {
    let key
    switch (cellHeader) {
      case "updated":
        key = "logged"
        break

      default:
        key = cellHeader.split(" ").join("-")
    }
    return key
  }

  const getSortOrder = order => {
    return order === "asc" ? "desc" : "asc"
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!tableData.length) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                No data found.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              let sortIcon = null
              if (getSortKey(header.toLowerCase()) === sortKey) {
                if (sortOrder === "asc") {
                  sortIcon = <ArrowUpwardIcon fontSize="small" />
                } else {
                  sortIcon = <ArrowDownwardIcon fontSize="small" />
                }
              }

              return (
                <TableCell
                  key={index}
                  onClick={e =>
                    handleCellOrder(
                      e,
                      getSortKey(header.toLowerCase()),
                      getSortOrder(sortOrder),
                    )
                  }
                >
                  <div
                    className={cn("tableHeader", {
                      tableHeaderOrdered: SORT_KEY.includes(
                        getSortKey(header.toLowerCase()),
                      ),
                    })}
                  >
                    <span>
                      <strong>
                        {header}{" "}
                        {tooltips.includes(header) && (
                          <TooltipInfo
                            className="tableTooltips"
                            title={INFO[`${header.toLowerCase()}`]}
                            fontSize="small"
                          />
                        )}
                      </strong>
                      {sortIcon && sortIcon}
                    </span>
                  </div>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((row, idx) => (
              <TableRow
                key={row.name || idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <div
                    className="user-basic-info"
                    onClick={viewProfile && viewProfile.bind(this, row.uid)}
                  >
                    {path !== "campaign" && (
                      <i
                        className="fa fa-2x fa-user user-icon"
                        aria-hidden="true"
                      ></i>
                    )}
                    <p className={cn({ "user-info-link": viewProfile })}>
                      {row.name ||
                        `${row.firstName || ""}  ${row.lastName || ""}`}
                    </p>
                  </div>
                </TableCell>
                {headers.map((header, index) => {
                  let displayData

                  // common
                  if (header === "Platform") {
                    displayData =
                      row["platform"]?.toUpperCase() ||
                      getPlatformData(row?.client)
                  } else if (header === "Location") {
                    displayData =
                      row?.location ||
                      row?.country ||
                      `${row.loc?.city || ""}, ${row.loc?.country || ""}` ||
                      "None"
                  } else if (header === "Location User") {
                    displayData = row?.location?.user || "None"
                  } else if (header === "Location Subs") {
                    displayData = row?.location?.subs || "None"
                  } else if (header === "Profile") {
                    displayData = getProfile({
                      uid: row.uid,
                      file: row.profile,
                      fileType: row.fileType,
                      fileName: row.name?.split(" ")[0] ?? "",
                      isBase64: row.isbase64,
                    })
                  } else if (header === "Query") {
                    displayData =
                      row?.query || row?.headline || row?.lastQuery || `All`
                  } else if (header === "Entries") {
                    displayData = row?.entries?.map((e, idx) => {
                      if (idx === row?.entries.length - 1) {
                        return e + " "
                      }
                      return e + ", "
                    })
                  } else if (header === "Search") {
                    displayData = row?.searchQuery || "None"
                  } else if (header === "Clicks") {
                    displayData = row?.clicks || row?.clickCount || "None"
                  } else if (header === "Created") {
                    if (row?.created) {
                      displayData = format(
                        new Date(row?.created),
                        "MMM dd, yyyy",
                      )
                    } else {
                      displayData = "None"
                    }
                  } else if (header === "Updated") {
                    if (row?.logged) {
                      displayData = format(
                        new Date(row?.logged),
                        "MMM dd, yyyy",
                      )
                    } else {
                      displayData = "None"
                    }
                  }

                  // campaign
                  else if (header === "Creation Date") {
                    if (row?.createdStartTime && row?.createdEndTime) {
                      displayData = `${format(
                        new Date(row?.createdStartTime),
                        "MMM dd, yyyy",
                      )} to ${format(
                        new Date(row?.createdEndTime),
                        "MMM dd, yyyy",
                      )}`
                    } else {
                      displayData = "None"
                    }
                  } else if (header === "Interaction Date") {
                    if (row?.lastStartTime && row?.lastEndTime) {
                      displayData = `${format(
                        new Date(row?.lastStartTime),
                        "MMM dd, yyyy",
                      )} to ${format(
                        new Date(row?.lastEndTime),
                        "MMM dd, yyyy",
                      )}`
                    } else {
                      displayData = "None"
                    }
                  } else if (header === "Job Role") {
                    displayData = row?.jobRole || "None"
                  } else if (header === "Frequency") {
                    displayData = row?.frequency || "None"
                  } else if (header === "Limit") {
                    displayData = row?.limit
                  } else if (header === "Milestone 1") {
                    displayData = row?.milestone1Users
                  } else if (header === "Milestone 2") {
                    displayData = row?.milestone2Users
                  } else if (header === "Milestone 3") {
                    displayData = row?.milestone3Users
                  } else if (header === "Milestone 4") {
                    displayData = row?.milestone4Users
                  }

                  // all
                  else {
                    displayData = row[header.toLowerCase()]
                  }

                  if (header !== "Name") {
                    return <TableCell key={index}>{displayData}</TableCell>
                  } else {
                    return null
                  }
                })}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow className="custom-table-centered">
            <TablePagination
              className="custom-table-pagination"
              rowsPerPageOptions={[50]}
              colSpan={12}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={(e, newPage) => handleChangePage(e, newPage)}
              onRowsPerPageChange={e => handleRowsPerPageChange(e)}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
