import { IconButton, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

export const TooltipInfo = ({
  className,
  title,
  fontSize = "medium",
  color = "primary",
}) => (
  <Tooltip className={className} title={title}>
    <IconButton>
      <InfoIcon color={color} fontSize={fontSize} />
    </IconButton>
  </Tooltip>
)
