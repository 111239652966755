import React, { useState, useContext } from "react"
import { useLocation } from "react-router-dom"

import { useAxiosQuery } from "../../hooks/use-axios-query"
import { AuthContext } from "../../context/auth-context"
import { consoleApi, ApiKeys } from "../../lib/api/console-api"
import { CustomTable } from "../../components/common/CustomTable"
import UserInfo from "../../components/common/UserInfo"
import { Spinner } from "../../components/common/loader"
import { TooltipInfo } from "../../components/common/ToolTip/ToolTip"

import "./profiles-page.css"

const INFO = {
  profiles:
    "List of users who have completed their profile with their cv/link.",
}

const TABLE_HEADERS = [
  "Name",
  "Platform",
  "Location",
  "Profile",
  "Email",
  "Clicks",
  "Query",
  "Created",
  "Updated",
]

export const ProfilesPage = () => {
  const { data } = useContext(AuthContext)
  const [profileViewing, setProfileViewing] = useState(false)
  const [selectedProfileId, setSelectedProfileId] = useState(null)
  const { pathname } = useLocation()

  const { data: profiles, isLoading } = useAxiosQuery(
    [ApiKeys.Profiles],
    consoleApi.getProfiles.bind(null, {
      offset: 0,
      sortBy: "logged",
      order: "desc",
    }),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const profileCloseHandler = () => {
    setProfileViewing(false)
  }

  const viewProfileHandler = uid => {
    setProfileViewing(true)
    setSelectedProfileId(uid)
  }

  if (isLoading) {
    return (
      <div className="profiles">
        <div className="profilesHeader">
          <h2>{`Profiles (${data?.data.profiles})`}</h2>
          <TooltipInfo title={INFO?.profiles} />
        </div>
        <hr />
        <Spinner />
      </div>
    )
  }

  return (
    <div className="profiles">
      <div className="profilesHeader">
        <h2>{`Profiles (${data?.data.profiles})`}</h2>
        <TooltipInfo title={INFO?.profiles} />
      </div>
      <hr />
      <CustomTable
        data={profiles?.data}
        totalCount={data?.data.profiles}
        viewProfile={viewProfileHandler}
        headers={TABLE_HEADERS}
        pathname={pathname}
      />

      {profileViewing && (
        <UserInfo closeProfile={profileCloseHandler} uid={selectedProfileId} />
      )}
    </div>
  )
}
